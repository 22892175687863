<app-navbar-one></app-navbar-one>

<!-- <div class="page-title-area item-bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>About</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>About</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->

<section class="audience-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="audience-image">
                    <img src="assets/img/about.png" alt="image" />
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="audience-content">
                    <h3>
                        {{ "Audience.Audience_Title_1" | translate }}
                        <span>{{
                            "Audience.Audience_Title_2" | translate
                        }}</span>
                        {{ "Audience.Audience_Title_3" | translate }}
                    </h3>
                    <div class="bar"></div>
                    <p>
                        {{ "Audience.Audience_Desc_1" | translate }}
                    </p>
                    <p>
                        {{ "Audience.Audience_Desc_2" | translate }}
                    </p>
                    <p>
                        {{ "Audience.Audience_Desc_3" | translate }}
                    </p>
                    <div class="audience-btn">
                        <a routerLink="/contact" class="default-btn">{{
                            "Audience.Get_Started" | translate
                        }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1">
            <img src="assets/img/shape/4.png" alt="image" />
        </div>
        <div class="shape-2 rotateme">
            <img src="assets/img/shape/5.svg" alt="image" />
        </div>
        <div class="shape-3">
            <img src="assets/img/shape/6.svg" alt="image" />
        </div>
        <div class="shape-4">
            <img src="assets/img/shape/7.png" alt="image" />
        </div>
        <div class="shape-5">
            <img src="assets/img/shape/8.png" alt="image" />
        </div>
    </div>
</section>

<section class="team-section pt-100 pb-70">
    <div class="container-fluid">
        <div class="section-title">
            <h2>
                {{ "team.team_Title_1" | translate }}
                <span>{{ "team.team_Title_2" | translate }}</span>
                {{ "team.team_Title_3" | translate }}
            </h2>
            <p>
                {{ "team.team_desc" | translate }}
            </p>
            <div class="bar"></div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="team-item">
                    <div class="image">
                        <img
                            src="assets/img/team/Amr-Ragaee.jpeg"
                            alt="image"
                        />
                        <ul class="social">
                            <li>
                                <a
                                    href="https://www.facebook.com/AmrRagaee0/"
                                    target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a
                                    href="https://x.com/amrragaee0"
                                    target="_blank"
                                    ><i>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 512 512"
                                            fill="#fff"
                                        >
                                            <path
                                                d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"
                                            /></svg></i
                                ></a>
                            </li>
                            <li>
                                <a
                                    href="https://www.linkedin.com/in/amrragaee0/"
                                    target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                            <li>
                                <a
                                    href="https://www.instagram.com/amrragaee0/"
                                    target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3 class="founder-name">
                            {{ "team.member_name_1" | translate }}
                        </h3>
                        <span class="founder-job-name">{{
                            "team.member_jop_title_1" | translate
                        }}</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="team-item">
                    <div class="image">
                        <img
                            src="assets/img/team/Ahmed-Gamal.jpeg"
                            alt="image"
                        />
                        <ul class="social">
                            <li>
                                <a
                                    href="https://www.facebook.com/geeme59?mibextid=JRoKGi"
                                    target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                            </li>

                            <li>
                                <a
                                    href="https://www.linkedin.com/in/ahmed-gamal-947a38b6?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                                    target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                            <li>
                                <a
                                    href="https://www.instagram.com/ahmedgamal_59/profilecard/?igsh=MWczeHo0czhvZG1nbQ=="
                                    target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3 class="founder-name">
                            {{ "team.member_name_2" | translate }}
                        </h3>
                        <span class="founder-job-name">{{
                            "team.member_jop_title_2" | translate
                        }}</span>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="row justify-content-center">
                    <div class="col-6 col-lg-2 col-md-4 col-sm-4">
                        <div class="team-item">
                            <div class="image">
                                <img
                                    src="assets/img/team/Malak-El-Sherif.jpeg"
                                    alt="image"
                                />
                            </div>
                            <div class="content">
                                <h3 class="member_name">
                                    {{ "team.member_name_3" | translate }}
                                </h3>
                                <span class="member_job-name">{{
                                    "team.member_jop_title_3" | translate
                                }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-2 col-md-4 col-sm-4">
                        <div class="team-item">
                            <div class="image">
                                <img
                                    src="assets/img/team/Habiba-Tarek.jpeg"
                                    alt="image"
                                />
                            </div>
                            <div class="content">
                                <h3 class="member_name">
                                    {{ "team.member_name_4" | translate }}
                                </h3>
                                <span class="member_job-name">{{
                                    "team.member_jop_title_4" | translate
                                }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-2 col-md-4 col-sm-4">
                        <div class="team-item">
                            <div class="image">
                                <img
                                    src="assets/img/team/Hossam-Benhawy.jpeg"
                                    alt="image"
                                />
                            </div>
                            <div class="content">
                                <h3 class="member_name">
                                    {{ "team.member_name_5" | translate }}
                                </h3>
                                <span class="member_job-name">{{
                                    "team.member_jop_title_5" | translate
                                }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-2 col-md-4 col-sm-4">
                        <div class="team-item">
                            <div class="image">
                                <img
                                    src="assets/img/team/Omnia-Hassan.jpeg"
                                    alt="image"
                                />
                            </div>
                            <div class="content">
                                <h3 class="member_name">
                                    {{ "team.member_name_6" | translate }}
                                </h3>
                                <span class="member_job-name">{{
                                    "team.member_jop_title_6" | translate
                                }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-2 col-md-4 col-sm-4">
                        <div class="team-item">
                            <div class="image">
                                <img
                                    src="assets/img/team/Mona-Omar.jpeg"
                                    alt="image"
                                />
                            </div>
                            <div class="content">
                                <h3 class="member_name">
                                    {{ "team.member_name_7" | translate }}
                                </h3>
                                <span class="member_job-name">{{
                                    "team.member_jop_title_7" | translate
                                }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1">
            <img src="assets/img/shape/4.png" alt="image" />
        </div>
        <div class="shape-2 rotateme">
            <img src="assets/img/shape/5.svg" alt="image" />
        </div>
        <div class="shape-3">
            <img src="assets/img/shape/6.svg" alt="image" />
        </div>
        <div class="shape-4">
            <img src="assets/img/shape/7.png" alt="image" />
        </div>
        <div class="shape-5">
            <img src="assets/img/shape/8.png" alt="image" />
        </div>
    </div>
</section>

<section class="clients-section pt-100 pb-70">
    <div class="container-clint">
        <div class="section-title">
            <h2>
                {{ "our_clints.title_1" | translate }}
                <span>{{ "our_clints.title_2" | translate }}</span>
                {{ "our_clints.title_3" | translate }}
            </h2>
            <p>{{ "our_clints.desc" | translate }}</p>
            <div class="bar"></div>
        </div>
        <div class="clients-slider owl-carousel owl-theme">
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>{{ "our_clints.client_word_1" | translate }}</p>
                <div class="clients-content">
                    <h3>{{ "our_clints.client_name_1" | translate }}</h3>
                    <span>{{ "our_clints.client_jop_1" | translate }}</span>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>{{ "our_clints.client_word_2" | translate }}</p>
                <div class="clients-content">
                    <h3>{{ "our_clints.client_name_2" | translate }}</h3>
                    <span>{{ "our_clints.client_jop_2" | translate }}</span>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon bg-fce8c9">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>{{ "our_clints.client_word_3" | translate }}</p>
                <div class="clients-content">
                    <h3>{{ "our_clints.client_name_3" | translate }}</h3>
                    <span>{{ "our_clints.client_jop_3" | translate }}</span>
                </div>
            </div>
        </div>
    </div>
</section>
