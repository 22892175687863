<app-navbar-one></app-navbar-one>

<div class="page-title-area item-bg-3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Log In</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Log In</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="login-section ptb-100">
    <div class="container">
        <div class="login-form">
            <div class="login-title">
                <h3>Welcome Back!</h3>
                <p>Please login to your account.</p>
            </div>
            <form>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="form-group">
                            <input type="email" class="form-control" placeholder="Email">
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="form-group">
                            <input type="password" class="form-control" placeholder="Password">
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="checkme">
                            <label class="form-check-label" for="checkme">Keep me Log In</label>
                        </div>
                    </div>
                    <div class="col-lg-12 text-start">
                        <p class="forgot-password"><a routerLink="/">Forgot Password?</a></p>
                    </div>
                    <div class="col-lg-12">
                        <div class="send-btn">
                            <a routerLink="/" class="default-btn">Log In Now</a>
                        </div>
                        <br>
                        <span>Don't have account? <a routerLink="/sign-up">Signup!</a></span>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</div>

<!-- <div>
    // Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBxKVoBQ3alo3tHaOVLmGFcVr1BMYaiy5M",
  authDomain: "oma-marketing.firebaseapp.com",
  projectId: "oma-marketing",
  storageBucket: "oma-marketing.appspot.com",
  messagingSenderId: "444653249143",
  appId: "1:444653249143:web:a74554d4d7c51efca89c72",
  measurementId: "G-WK68Y9RZ3T"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
</div> -->