import { Component, OnInit, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Dropdown } from 'bootstrap';
@Component({
  selector: 'app-navbar-one',
  templateUrl: './navbar-one.component.html',
  styleUrls: ['./navbar-one.component.scss']
})
export class NavbarOneComponent implements OnInit ,AfterViewInit  {
  @ViewChild('languageDropdown') languageDropdown!: ElementRef;
  selectedLanguage = "en";

  constructor(private translate: TranslateService) {
    // Set the default language to English
    translate.setDefaultLang('en');
  }
  ngAfterViewInit(): void {
    const dropdownElement = this.languageDropdown?.nativeElement;
    if (dropdownElement) {
      new Dropdown(dropdownElement);
    }
  }
  ngOnInit(): void {
  }
  toggleLanguage() {
    // Toggle between 'en' and 'ar'
    this.selectedLanguage = this.selectedLanguage === 'en' ? 'ar' : 'en';
    this.translate.use(this.selectedLanguage).subscribe(() => {
      // Change the document direction if the language is Arabic
      const direction = this.selectedLanguage === 'ar' ? 'rtl' : 'ltr';
      document.documentElement.dir = direction;
      document.documentElement.lang = this.selectedLanguage;
    });
  }
}
