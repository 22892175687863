<app-navbar-one></app-navbar-one>



<section class="about-section">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="about-tab">
                    <div class="section-title">
                        <h2>
                            {{ "OUR_SERVICES.TITLE_1_P1" | translate }}
                            <span>{{
                                "OUR_SERVICES.TITLE_1_P2" | translate
                            }}</span>
                            {{ "OUR_SERVICES.TITLE_1_P3" | translate }}
                        </h2>
                        <p>
                            {{ "OUR_SERVICES.TITLE_2" | translate }}
                        </p>
                        <div class="bar"></div>
                    </div>
                    <div class="tab schedule-list-tab text-center">
                        <ul class="tabs">
                            <li><a href="#">    {{
                                "OUR_SERVICES.head_line_title_1"
                                    | translate
                            }}</a></li>
                            <li><a href="#">    {{
                                "OUR_SERVICES.head_line_title_2"
                                    | translate
                            }}</a></li>
                            <li><a href="#">    {{
                                "OUR_SERVICES.head_line_title_3"
                                    | translate
                            }}</a></li>
                            <!-- <li><a href="#">    {{
                                "OUR_SERVICES.head_line_title_3"
                                    | translate
                            }}</a></li> -->
                        </ul>
                        <div class="tab_content">
                            <div class="tabs_item">
                                <div class="row g-4 justify-content-center align-items-stretch">
                                    <div class="col-lg-4 col-md-6">
                                        <div class="single-services-box">
                                            <div class="icon bg-79e8e2">
                                                <i
                                                    class="flaticon-promotion"
                                                ></i>
                                            </div>
                                            <h3>
                                                {{
                                                    "OUR_SERVICES.DM_TITLE_1"
                                                        | translate
                                                }}
                                            </h3>
                                            <p>
                                                {{
                                                    "OUR_SERVICES.DM_DESC_1"
                                                        | translate
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="single-services-box">
                                            <div class="icon bg-79e8e2">
                                                <i
                                                    class="flaticon-promotion"
                                                ></i>
                                            </div>
                                            <h3>
                                                {{
                                                    "OUR_SERVICES.DM_TITLE_2"
                                                        | translate
                                                }}
                                            </h3>
                                            <p>
                                                {{
                                                    "OUR_SERVICES.DM_DESC_2"
                                                        | translate
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="single-services-box">
                                            <div class="icon bg-79e8e2">
                                                <i
                                                class="flaticon-promotion"
                                            ></i>
                                            </div>
                                            <h3>
                                                {{
                                                    "OUR_SERVICES.DM_TITLE_3"
                                                        | translate
                                                }}
                                            </h3>
                                            <p>
                                                {{
                                                    "OUR_SERVICES.DM_DESC_3"
                                                        | translate
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="single-services-box">
                                            <div class="icon bg-79e8e2">
                                                <i
                                                    class="flaticon-promotion"
                                                ></i>
                                            </div>
                                            <h3>
                                                {{
                                                    "OUR_SERVICES.DM_TITLE_4"
                                                        | translate
                                                }}
                                            </h3>
                                            <p>
                                                {{
                                                    "OUR_SERVICES.DM_DESC_4"
                                                        | translate
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="single-services-box">
                                            <div class="icon bg-79e8e2">
                                                <i
                                                    class="flaticon-promotion"
                                                ></i>
                                            </div>
                                            <h3>
                                                {{
                                                    "OUR_SERVICES.DM_TITLE_5"
                                                        | translate
                                                }}
                                            </h3>
                                            <p>
                                                {{
                                                    "OUR_SERVICES.DM_DESC_5"
                                                        | translate
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tabs_item">
                                <div class="row g-4 justify-content-center align-items-stretch">
                                    <div class="col-lg-4 col-md-6">
                                        <div class="single-services-box">
                                            <div class="icon bg-79e8e2">
                                                <i class="flaticon-setting"></i>
                                            </div>
                                            <h3>
                                                {{
                                                    "OUR_SERVICES.WD_TITLE_1"
                                                        | translate
                                                }}
                                            </h3>
                                            <p>
                                                {{
                                                    "OUR_SERVICES.WD_DESC_1"
                                                        | translate
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="single-services-box">
                                            <div class="icon bg-79e8e2">
                                                <i class="flaticon-setting"></i>
                                            </div>
                                            <h3>
                                                {{
                                                    "OUR_SERVICES.WD_TITLE_2"
                                                        | translate
                                                }}
                                            </h3>
                                            <p>
                                                {{
                                                    "OUR_SERVICES.WD_DESC_2"
                                                        | translate
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="single-services-box">
                                            <div class="icon bg-79e8e2">
                                                <i class="flaticon-setting"></i>
                                            </div>
                                            <h3>
                                                {{
                                                    "OUR_SERVICES.WD_TITLE_3"
                                                        | translate
                                                }}
                                            </h3>
                                            <p>
                                                {{
                                                    "OUR_SERVICES.WD_DESC_3"
                                                        | translate
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tabs_item">
                                <div class="row g-4 justify-content-center align-items-stretch">
                                    <div class="col-lg-4 col-md-6">
                                        <div class="single-services-box">
                                            <div class="icon bg-79e8e2">
                                                <i class='bx bx-palette'></i>
                                            </div>
                                            <h3>
                                                {{
                                                    "OUR_SERVICES.DESIGN_TITLE"
                                                        | translate
                                                }}
                                            </h3>
                                            <p>
                                                {{
                                                    "OUR_SERVICES.DESIGN_DESC"
                                                        | translate
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
