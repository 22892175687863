import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-navbar-two',
  templateUrl: './navbar-two.component.html',
  styleUrls: ['./navbar-two.component.scss']
})
export class NavbarTwoComponent implements OnInit {
  selectedLanguage = "en";
  constructor(private translate: TranslateService) {
    // Set the default language to English
    translate.setDefaultLang('en');
  }

  ngOnInit(): void {
  }
  toggleLanguage() {
    // Toggle between 'en' and 'ar'
    this.selectedLanguage = this.selectedLanguage === 'en' ? 'ar' : 'en';
    this.translate.use(this.selectedLanguage).subscribe(() => {
      // Change the document direction if the language is Arabic
      const direction = this.selectedLanguage === 'ar' ? 'rtl' : 'ltr';
      document.documentElement.dir = direction;
      document.documentElement.lang = this.selectedLanguage;
    });
  }
}
