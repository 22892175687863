import { Component, OnInit } from '@angular/core';
import { OwlOptions } from "ngx-owl-carousel-o";
import * as bootstrap from 'bootstrap';
interface Client {
  clientTmg: string;
}
interface ProjectImg {
  projectImg: string;
}
@Component({
  selector: 'app-projects-one',
  templateUrl: './projects-one.component.html',
  styleUrls: ['./projects-one.component.scss']
})
export class ProjectsOneComponent implements OnInit {

  clients:Client[] =[
    {clientTmg:"assets/img/protfolio/logos/1.png"},
    {clientTmg:"assets/img/protfolio/logos/2.png"},
    {clientTmg:"assets/img/protfolio/logos/3.png"},
    {clientTmg:"assets/img/protfolio/logos/4.png"},
    {clientTmg:"assets/img/protfolio/logos/5.png"},
    {clientTmg:"assets/img/protfolio/logos/6.png"},
    {clientTmg:"assets/img/protfolio/logos/7.png"},
    {clientTmg:"assets/img/protfolio/logos/8.png"},
    {clientTmg:"assets/img/protfolio/logos/9.png"},
    {clientTmg:"assets/img/protfolio/logos/10.png"},
    {clientTmg:"assets/img/protfolio/logos/11.png"},
    {clientTmg:"assets/img/protfolio/logos/12.png"},
    {clientTmg:"assets/img/protfolio/logos/13.png"},
    {clientTmg:"assets/img/protfolio/logos/14.png"},
    {clientTmg:"assets/img/protfolio/logos/15.png"},
    {clientTmg:"assets/img/protfolio/logos/16.png"},
    {clientTmg:"assets/img/protfolio/logos/17.png"},
    {clientTmg:"assets/img/protfolio/logos/18.png"},
    {clientTmg:"assets/img/protfolio/logos/19.png"},
    {clientTmg:"assets/img/protfolio/logos/20.png"},
    {clientTmg:"assets/img/protfolio/logos/21.png"},
    {clientTmg:"assets/img/protfolio/logos/22.png"},
    {clientTmg:"assets/img/protfolio/logos/23.png"},
    {clientTmg:"assets/img/protfolio/logos/24.png"},
    {clientTmg:"assets/img/protfolio/logos/25.png"},
    {clientTmg:"assets/img/protfolio/logos/26.png"},
    {clientTmg:"assets/img/protfolio/logos/27.png"},
    {clientTmg:"assets/img/protfolio/logos/28.png"},
    {clientTmg:"assets/img/protfolio/logos/29.png"},
    {clientTmg:"assets/img/protfolio/logos/30.png"},
    {clientTmg:"assets/img/protfolio/logos/31.png"},
    {clientTmg:"assets/img/protfolio/logos/32.png"},
    {clientTmg:"assets/img/protfolio/logos/33.png"},
    {clientTmg:"assets/img/protfolio/logos/34.png"},
    {clientTmg:"assets/img/protfolio/logos/35.png"},
    {clientTmg:"assets/img/protfolio/logos/36.png"},
    {clientTmg:"assets/img/protfolio/logos/37.png"},
    {clientTmg:"assets/img/protfolio/logos/38.png"},
    {clientTmg:"assets/img/protfolio/logos/39.png"},
    {clientTmg:"assets/img/protfolio/logos/40.png"},
    {clientTmg:"assets/img/protfolio/logos/41.png"},
    {clientTmg:"assets/img/protfolio/logos/42.png"},
    {clientTmg:"assets/img/protfolio/logos/43.png"},
    {clientTmg:"assets/img/protfolio/logos/44.png"},
    {clientTmg:"assets/img/protfolio/logos/45.png"},
    {clientTmg:"assets/img/protfolio/logos/46.png"},
    {clientTmg:"assets/img/protfolio/logos/47.png"},
    {clientTmg:"assets/img/protfolio/logos/48.png"},
    {clientTmg:"assets/img/protfolio/logos/49.png"},
    {clientTmg:"assets/img/protfolio/logos/50.png"},
    {clientTmg:"assets/img/protfolio/logos/51.png"},
    {clientTmg:"assets/img/protfolio/logos/52.png"},

  ];
  projectImg:ProjectImg[] =[
    {projectImg:"assets/img/protfolio/3.png"},
    {projectImg:"assets/img/protfolio/5.png"},
    {projectImg:"assets/img/protfolio/7.png"},
    {projectImg:"assets/img/protfolio/9.png"},
    {projectImg:"assets/img/protfolio/13.png"},
    {projectImg:"assets/img/protfolio/14.png"},
    {projectImg:"assets/img/protfolio/17.png"},
    {projectImg:"assets/img/protfolio/20.png"},
    {projectImg:"assets/img/protfolio/22.png"},
    {projectImg:"assets/img/protfolio/23.png"},
    {projectImg:"assets/img/protfolio/26.png"},
    {projectImg:"assets/img/protfolio/27.png"},
    {projectImg:"assets/img/protfolio/28.png"},
    {projectImg:"assets/img/protfolio/29.png"},
    {projectImg:"assets/img/protfolio/30.png"},
    {projectImg:"assets/img/protfolio/31.png"},
    {projectImg:"assets/img/protfolio/34.png"},
    {projectImg:"assets/img/protfolio/35.png"},
    {projectImg:"assets/img/protfolio/36.png"},
    {projectImg:"assets/img/protfolio/37.png"},
    {projectImg:"assets/img/protfolio/38.png"},
    {projectImg:"assets/img/protfolio/40.png"},
    {projectImg:"assets/img/protfolio/41.png"},
    {projectImg:"assets/img/protfolio/42.png"},
    {projectImg:"assets/img/protfolio/43.png"},
  ];
  constructor() {}
  selectedImage: string = '';

  openModal(image: string): void {
    this.selectedImage = image;
  
    const modalElement = document.getElementById('imageModal') as HTMLElement;
    const modal = new bootstrap.Modal(modalElement);
    
    // Get modal dialog and check if it exists
    const modalDialog = modalElement.querySelector('.modal-dialog') as HTMLElement | null;
    
    // if (modalDialog) {
    //   modalDialog.style.maxWidth = '50%';
    // }
  
    modal.show();
  }
  ngOnInit(): void {}
  customOptions: OwlOptions = {
      items: 4,
      loop: true,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: false,
      rtl: true,
      dots: false,
      margin: 10,
      navSpeed: 700,
      navText: [
          `<i class='bx bx-chevron-left'></i>`,
          `<i class='bx bx-chevron-right'></i>`,
      ],
      responsive: {
          0: {
              items: 2,
          },
          400: {
              items: 3,
          },
          740: {
              items: 4,
          },
          940: {
              items: 4,
          },
      },
      nav: true,
  };

}
