<section class="footer-section pt-100 pb-70">
    <div class="container">
        <div class="subscribe-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="subscribe-content">
                        <h2>{{ "FOOTER.SUBSCRIBE_TITLE" | translate }}</h2>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <form
                        [formGroup]="subscribeForm"
                        (ngSubmit)="onSubscribeFormSubmit(subscribeForm.value)"
                        class="newsletter-form d-flex"
                    >
                        <input
                            type="email"
                            class="input-newsletter"
                            [placeholder]="
                                'FOOTER.SUBSCRIBE_PLACEHOLDER' | translate
                            "
                            formControlName="email"
                        />
                        <button type="submit">
                            {{ "FOOTER.SUBSCRIBE_BOTTON" | translate }}
                        </button>
                    </form>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="footer-heading">
                        <h3>{{ "FOOTER.FOOTER_TITLE" | translate }}</h3>
                    </div>
                    <p>{{ "FOOTER.FOOTER_DESC" | translate }}</p>
                    <ul class="footer-social">
                        <li>
                            <a href="https://wa.me/966559634398" target="_blank"
                                ><i class="bx bxl-whatsapp"></i
                            ></a>
                        </li>
                        <li>
                            <a
                                href="https://www.facebook.com/omaagency"
                                target="_blank"
                                ><i class="flaticon-facebook"></i
                            ></a>
                        </li>
                        <li>
                            <a href="https://x.com/oma_marketing" target="_blank"
                                ><i>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 512 512"
                                    >
                                        <!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                                        <path
                                            d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"
                                        /></svg>
                                        </i
                            ></a>
                        </li>
                        <li>
                            <a
                                href="https://www.linkedin.com/in/omamarketingco/"
                                target="_blank"
                                ><i class="bx bxl-linkedin"></i
                            ></a>
                        </li>
                        <li>
                            <a
                                href="https://www.instagram.com/oma.marketing.eg/"
                                target="_blank"
                                ><i class="flaticon-instagram"></i
                            ></a>
                        </li>
                        <li>
                            <a
                                href="https://www.tiktok.com/@oma.marketing.eg"
                                target="_blank"
                                ><i class='bx bxl-tiktok'></i></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="footer-heading">
                        <h3>{{ "FOOTER.Important_Links" | translate }}</h3>
                    </div>
                    <ul class="footer-quick-links p-0">
                        <li>
                            <a routerLink="/about">{{
                                "FOOTER.ABOUT_US" | translate
                            }}</a>
                        </li>
                        <li>
                            <a routerLink="/services-one">{{
                                "FOOTER.OUR_SERVICES" | translate
                            }}</a>
                        </li>
                        <li>
                            <a routerLink="/projects-one">{{
                                "FOOTER.PROJECTS" | translate
                            }}</a>
                        </li>
                    
                        <li>
                            <a routerLink="/team">{{
                                "FOOTER.TEAM" | translate
                            }}</a>
                        </li>
                        <li>
                            <a routerLink="/contact">{{
                                "FOOTER.CONTACT" | translate
                            }}</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="footer-heading">
                        <h3>{{ "FOOTER.CONTACT" | translate }}</h3>
                    </div>
                    <div class="footer-info-contact d-flex">
                        <i class="flaticon-phone-call icon"></i>
                        <div>
                            <h3>{{ "FOOTER.PHONE" | translate }}</h3>
                            <div class="row">
                                <div class="col-md-4">
                                    <span
                                        ><a href="tel:+201097774231">{{
                                            "FOOTER.PHONE_NUM_1" | translate
                                        }}</a></span
                                    >
                                </div>
                                <div class="col-md-4">
                                    <span
                                        ><a href="tel:+201220733684">{{
                                            "FOOTER.PHONE_NUM_2" | translate
                                        }}</a></span
                                    >
                                </div>
                                <div class="col-md-4">
                                    <span
                                        ><a href="tel:+966559634398">{{
                                            "FOOTER.PHONE_NUM_3" | translate
                                        }}</a></span
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="footer-info-contact d-flex">
                        <i class="flaticon-envelope icon"></i>
                        <div>
                            <h3>{{ "FOOTER.E_MAIL" | translate }}</h3>
                            <span
                                ><a href="">{{
                                    "FOOTER.E_MAIL_OMA" | translate
                                }}</a></span
                            >
                        </div>
                    </div>
                    <div class="footer-info-contact d-flex">
                        <i class="flaticon-pin icon"></i>
                        <div>
                            <h3>{{ "FOOTER.ADDRESS" | translate }}</h3>
                            <span
                                ><a href="#" target="_blank">{{
                                    "FOOTER.ADDRESS_1" | translate
                                }}</a></span
                            >
                            <br />
                            <span
                                ><a href="#" target="_blank">{{
                                    "FOOTER.ADDRESS_2" | translate
                                }}</a></span
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="copyright-area">
    <div class="container">
        <div class="copyright-area-content">
            <div class="row align-items-center">
                <div class="col-lg-12 text-center">
                    <p>
                        {{ "FOOTER.Copyright_1" | translate }}
                        <Span class="oma">{{
                            "FOOTER.Copyright_2" | translate
                        }}</Span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="go-top">
    <i class="bx bx-chevron-up"></i><i class="bx bx-chevron-up"></i>
</div>

