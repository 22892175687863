<app-navbar-one></app-navbar-one>
<div class="main-banner-area-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center grid gap-0 row-gap-md-5">
                    <div class="col-lg-6 col-md-12 order-2 order-lg-1">
                        <div class="main-banner-content pe-3">
                            <span>
                                {{ "HEADDER.TITLE" | translate }}
                            </span>
                            <h1>
                                {{ "HEADDER.TITLE_2" | translate }}
                            </h1>
                            <p>{{ "HEADDER.DESC" | translate }}</p>

                            <div class="banner-btn">
                                <a
                                    routerLink="/services-one"
                                    class="default-btn"
                                    >{{ "HEADDER.GET_START" | translate }}</a
                                >
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 order-1 order-lg-2">
                        <div class="banner-image">
                            <img
                                src="assets/img/home-four/img1.png"
                                alt="image"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1">
            <img src="assets/img/shape/4.png" alt="image" />
        </div>
        <div class="shape-2 rotateme">
            <img src="assets/img/shape/5.svg" alt="image" />
        </div>
        <div class="shape-3">
            <img src="assets/img/shape/6.svg" alt="image" />
        </div>
        <div class="shape-4">
            <img src="assets/img/shape/7.png" alt="image" />
        </div>
        <div class="shape-5">
            <img src="assets/img/shape/8.png" alt="image" />
        </div>
    </div>
</div>
<section class="audience-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="audience-image">
                    <img src="assets/img/about.png" alt="image" />
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="audience-content">
                    <h3>
                        {{ "Audience.Audience_Title_1" | translate }}
                        <span>{{
                            "Audience.Audience_Title_2" | translate
                        }}</span>
                        {{ "Audience.Audience_Title_3" | translate }}
                    </h3>
                    <div class="bar"></div>
                    <p>
                        {{ "Audience.Audience_Desc_1" | translate }}
                    </p>
                    <p>
                        {{ "Audience.Audience_Desc_2" | translate }}
                    </p>
                    <p>
                        {{ "Audience.Audience_Desc_3" | translate }}
                    </p>
                    <div class="audience-btn">
                        <a routerLink="/contact" class="default-btn">{{
                            "Audience.Get_Started" | translate
                        }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1">
            <img src="assets/img/shape/4.png" alt="image" />
        </div>
        <div class="shape-2 rotateme">
            <img src="assets/img/shape/5.svg" alt="image" />
        </div>
        <div class="shape-3">
            <img src="assets/img/shape/6.svg" alt="image" />
        </div>
        <div class="shape-4">
            <img src="assets/img/shape/7.png" alt="image" />
        </div>
        <div class="shape-5">
            <img src="assets/img/shape/8.png" alt="image" />
        </div>
    </div>
</section>
<section class="services-section bg-color pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>
                {{ "OUR_SERVICES.TITLE_1_P1" | translate }}
                <span>{{ "OUR_SERVICES.TITLE_1_P2" | translate }}</span>
                {{ "OUR_SERVICES.TITLE_1_P3" | translate }}
            </h2>
            <p>
                {{ "OUR_SERVICES.TITLE_2" | translate }}
            </p>
            <div class="bar"></div>
        </div>
        <div class="row g-4 align-items-stretch">
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-79e8e2">
                        <i class="flaticon-promotion"></i>
                    </div>
                    <h3>{{ "OUR_SERVICES.head_line_title_1" | translate }}</h3>
                    <p>
                        {{ "OUR_SERVICES.head_line_desc_1" | translate }}
                    </p>
                    <a routerLink="/services-one" class="read-btn">{{
                        "OUR_SERVICES.READ_MORE" | translate
                    }}</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-deb0fe">
                        <i class="flaticon-setting"></i>
                    </div>
                    <h3>{{ "OUR_SERVICES.head_line_title_2" | translate }}</h3>
                    <p>
                        {{ "OUR_SERVICES.head_line_desc_2" | translate }}
                    </p>
                    <a routerLink="/services-one" class="read-btn">{{
                        "OUR_SERVICES.READ_MORE" | translate
                    }}</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-84b7fd">
                        <i class='bx bx-palette'></i>
                    </div>
                    <h3>{{ "OUR_SERVICES.head_line_title_3" | translate }}</h3>
                    <p>
                        {{ "OUR_SERVICES.head_line_desc_3" | translate }}
                    </p>
                    <a routerLink="/services-one" class="read-btn">{{
                        "OUR_SERVICES.READ_MORE" | translate
                    }}</a>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1">
            <img src="assets/img/shape/4.png" alt="image" />
        </div>
        <div class="shape-2 rotateme">
            <img src="assets/img/shape/5.svg" alt="image" />
        </div>
        <div class="shape-3">
            <img src="assets/img/shape/6.svg" alt="image" />
        </div>
        <div class="shape-4">
            <img src="assets/img/shape/7.png" alt="image" />
        </div>
        <div class="shape-5">
            <img src="assets/img/shape/8.png" alt="image" />
        </div>
    </div>
</section>
<section class="solutions-section pb-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="solutions-content-area">
                    <div class="solutions-content">
                        <h3>
                            {{ "Solutions.Solutions_Title_1" | translate }}
                            <span>{{
                                "Solutions.Solutions_Title_2" | translate
                            }}</span>
                            {{ "Solutions.Solutions_Title_3" | translate }}
                        </h3>
                        <div class="bar"></div>
                        <p>
                            {{ "Solutions.Solutions_Desc" | translate }}
                        </p>
                    </div>
                    <div class="solutions-details">
                        <div class="icon bg-d5e6fe">
                            <i class="flaticon-laptop"></i>
                        </div>
                        <h3>{{ "Solutions.feature_title_1" | translate }}</h3>
                        <p>
                            {{ "Solutions.feature_desc_1" | translate }}
                        </p>
                    </div>
                    <div class="solutions-details">
                        <div class="icon bg-fce8c9">
                            <i class="flaticon-analysis"></i>
                        </div>
                        <h3>{{ "Solutions.feature_title_2" | translate }}</h3>
                        <p>
                            {{ "Solutions.feature_desc_2" | translate }}
                        </p>
                    </div>
                    <div class="solutions-details">
                        <div class="icon bg-d3fbf9">
                            <i class="flaticon-software"></i>
                        </div>
                        <h3>{{ "Solutions.feature_title_3" | translate }}</h3>
                        <p>
                            {{ "Solutions.feature_desc_3" | translate }}
                        </p>
                    </div>
                    <div class="solutions-details">
                        <div class="icon">
                            <i class="flaticon-blog"></i>
                        </div>
                        <h3>{{ "Solutions.feature_title_4" | translate }}</h3>
                        <p>
                            {{ "Solutions.feature_desc_4" | translate }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 pr-0">
                <div class="solutions-image">
                    <img src="assets/img/solutions.png" alt="image" />
                </div>
            </div>
        </div>
    </div>
</section>
<section class="design-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 order-2 order-lg-1">
                <div class="design-image">
                    <img src="assets/img/design.png" alt="image" />
                </div>
            </div>
            <div class="col-lg-6 col-md-12 order-1 order-lg-2">
                <div class="design-content">
                    <h3>
                        {{ "Development.Development_Title_1" | translate }}
                        <span>{{
                            "Development.Development_Title_2" | translate
                        }}</span>
                    </h3>
                    <div class="bar"></div>
                    <p>
                        {{ "Development.Development_Desc" | translate }}
                    </p>
                    <ul class="design-list">
                        <li>
                            <i class="flaticon-check"></i>
                            {{ "Development.feature_title_1" | translate }}
                        </li>
                        <li>
                            <i class="flaticon-check"></i>
                            {{ "Development.feature_title_2" | translate }}
                        </li>
                        <li>
                            <i class="flaticon-check"></i>
                            {{ "Development.feature_title_3" | translate }}
                        </li>
                        <li>
                            <i class="flaticon-check"></i>
                            {{ "Development.feature_title_4" | translate }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1">
            <img src="assets/img/shape/4.png" alt="image" />
        </div>
        <div class="shape-2 rotateme">
            <img src="assets/img/shape/5.svg" alt="image" />
        </div>
        <div class="shape-3">
            <img src="assets/img/shape/6.svg" alt="image" />
        </div>
        <div class="shape-4">
            <img src="assets/img/shape/7.png" alt="image" />
        </div>
        <div class="shape-5">
            <img src="assets/img/shape/8.png" alt="image" />
        </div>
    </div>
</section>
<section class="counter-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>
                {{ "counter.counter_Title_1" | translate }}
                <span>{{ "counter.counter_Title_2" | translate }}</span>
                {{ "counter.counter_Title_3" | translate }}
                <span>{{ "counter.counter_Title_4" | translate }}</span>
            </h2>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-counter">
                    <div class="icon">
                        <i class="flaticon-check"></i>
                    </div>
                    <h3><span class="odometer" data-count="2132">00</span></h3>
                    <p>{{ "counter.counter_desc_1" | translate }}</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-counter">
                    <div class="icon">
                        <i class="flaticon-happy"></i>
                    </div>
                    <h3><span class="odometer" data-count="982">00</span></h3>
                    <p>{{ "counter.counter_desc_2" | translate }}</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-counter">
                    <div class="icon">
                        <i class="flaticon-technical-support"></i>
                    </div>
                    <h3><span class="odometer" data-count="736">00</span></h3>
                    <p>{{ "counter.counter_desc_3" | translate }}</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-counter">
                    <div class="icon">
                        <i class="flaticon-trophy"></i>
                    </div>
                    <h3><span class="odometer" data-count="359">00</span></h3>
                    <p>{{ "counter.counter_desc_4" | translate }}</p>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="team-section pt-100 pb-70">
    <div class="container-fluid">
        <div class="section-title">
            <h2>
                {{ "team.team_Title_1" | translate }}
                <span>{{ "team.team_Title_2" | translate }}</span>
                {{ "team.team_Title_3" | translate }}
            </h2>
            <p>
                {{ "team.team_desc" | translate }}
            </p>
            <div class="bar"></div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="team-item">
                    <div class="image">
                        <img
                            src="assets/img/team/Amr-Ragaee.jpeg"
                            alt="image"
                        />
                        <ul class="social">
                            <li>
                                <a
                                    href="https://www.facebook.com/AmrRagaee0/"
                                    target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a
                                    href="https://x.com/amrragaee0"
                                    target="_blank"
                                    ><i>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 512 512"
                                            fill="#fff"
                                        >
                                            <path
                                                d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"
                                            /></svg></i
                                ></a>
                            </li>
                            <li>
                                <a
                                    href="https://www.linkedin.com/in/amrragaee0/"
                                    target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                            <li>
                                <a
                                    href="https://www.instagram.com/amrragaee0/"
                                    target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3 class="founder-name">
                            {{ "team.member_name_1" | translate }}
                        </h3>
                        <span class="founder-job-name">{{
                            "team.member_jop_title_1" | translate
                        }}</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="team-item">
                    <div class="image">
                        <img
                            src="assets/img/team/Ahmed-Gamal.jpeg"
                            alt="image"
                        />
                        <ul class="social">
                            <li>
                                <a
                                    href="https://www.facebook.com/geeme59?mibextid=JRoKGi"
                                    target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                            </li>

                            <li>
                                <a
                                    href="https://www.linkedin.com/in/ahmed-gamal-947a38b6?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                                    target="_blank"
                                    ><i class="bx bxl-linkedin"></i
                                ></a>
                            </li>
                            <li>
                                <a
                                    href="https://www.instagram.com/ahmedgamal_59/profilecard/?igsh=MWczeHo0czhvZG1nbQ=="
                                    target="_blank"
                                    ><i class="bx bxl-instagram"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3 class="founder-name">
                            {{ "team.member_name_2" | translate }}
                        </h3>
                        <span class="founder-job-name">{{
                            "team.member_jop_title_2" | translate
                        }}</span>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="row justify-content-center">
                    <div class="col-6 col-lg-2 col-md-4 col-sm-4">
                        <div class="team-item">
                            <div class="image">
                                <img
                                    src="assets/img/team/Malak-El-Sherif.jpeg"
                                    alt="image"
                                />
                            </div>
                            <div class="content">
                                <h3 class="member_name">
                                    {{ "team.member_name_3" | translate }}
                                </h3>
                                <span class="member_job-name">{{
                                    "team.member_jop_title_3" | translate
                                }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-2 col-md-4 col-sm-4">
                        <div class="team-item">
                            <div class="image">
                                <img
                                    src="assets/img/team/Habiba-Tarek.jpeg"
                                    alt="image"
                                />
                            </div>
                            <div class="content">
                                <h3 class="member_name">
                                    {{ "team.member_name_4" | translate }}
                                </h3>
                                <span class="member_job-name">{{
                                    "team.member_jop_title_4" | translate
                                }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-2 col-md-4 col-sm-4">
                        <div class="team-item">
                            <div class="image">
                                <img
                                    src="assets/img/team/Hossam-Benhawy.jpeg"
                                    alt="image"
                                />
                            </div>
                            <div class="content">
                                <h3 class="member_name">
                                    {{ "team.member_name_5" | translate }}
                                </h3>
                                <span class="member_job-name">{{
                                    "team.member_jop_title_5" | translate
                                }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-2 col-md-4 col-sm-4">
                        <div class="team-item">
                            <div class="image">
                                <img
                                    src="assets/img/team/Omnia-Hassan.jpeg"
                                    alt="image"
                                />
                            </div>
                            <div class="content">
                                <h3 class="member_name">
                                    {{ "team.member_name_6" | translate }}
                                </h3>
                                <span class="member_job-name">{{
                                    "team.member_jop_title_6" | translate
                                }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-2 col-md-4 col-sm-4">
                        <div class="team-item">
                            <div class="image">
                                <img
                                    src="assets/img/team/Mona-Omar.jpeg"
                                    alt="image"
                                />
                            </div>
                            <div class="content">
                                <h3 class="member_name">
                                    {{ "team.member_name_7" | translate }}
                                </h3>
                                <span class="member_job-name">{{
                                    "team.member_jop_title_7" | translate
                                }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1">
            <img src="assets/img/shape/4.png" alt="image" />
        </div>
        <div class="shape-2 rotateme">
            <img src="assets/img/shape/5.svg" alt="image" />
        </div>
        <div class="shape-3">
            <img src="assets/img/shape/6.svg" alt="image" />
        </div>
        <div class="shape-4">
            <img src="assets/img/shape/7.png" alt="image" />
        </div>
        <div class="shape-5">
            <img src="assets/img/shape/8.png" alt="image" />
        </div>
    </div>
</section>
<section class="protfolio-section">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="about-tab">
                    <div class="section-title">
                        <h2>
                            {{ "OUR_PORTFOLIO.TITLE_1_P1" | translate }}
                            <span>{{
                                "OUR_PORTFOLIO.TITLE_1_P2" | translate
                            }}</span>
                            {{ "OUR_PORTFOLIO.TITLE_1_P3" | translate }}
                        </h2>
                        <p>
                            {{ "OUR_PORTFOLIO.TITLE_2" | translate }}
                        </p>
                        <div class="bar"></div>
                    </div>
                    <div class="tab schedule-list-tab">
                        <ul class="tabs">
                            <li>
                                <a href="#"
                                    ><span
                                        ><i class="flaticon-internet"></i>
                                        {{
                                            "OUR_PORTFOLIO.head_line_title_1"
                                                | translate
                                        }}</span
                                    ></a
                                >
                            </li>
                            <li>
                                <a href="#"
                                    ><span
                                        ><i class="flaticon-optimize"></i>
                                        {{
                                            "OUR_PORTFOLIO.head_line_title_2"
                                                | translate
                                        }}</span
                                    ></a
                                >
                            </li>
                            <li>
                                <a href="#"
                                    ><span
                                        ><i class="flaticon-data"></i>
                                        {{
                                            "OUR_PORTFOLIO.head_line_title_3"
                                                | translate
                                        }}</span
                                    ></a
                                >
                            </li>
                            <li>
                                <a href="#"
                                    ><span
                                        ><i class="flaticon-software"></i>
                                        {{
                                            "OUR_PORTFOLIO.head_line_title_4"
                                                | translate
                                        }}</span
                                    ></a
                                >
                            </li>
                        </ul>
                        <div class="tab_content">
                            <div class="tabs_item">
                                <div class="mb-5 text-center">
                                    <h4 class="mb-5">
                                        {{
                                            "OUR_PORTFOLIO.LAST_PROJECT"
                                                | translate
                                        }}
                                    </h4>
                                    <owl-carousel-o [options]="customOptions" >
                                        <ng-template
                                            carouselSlide
                                            *ngFor="
                                                let projectImg of projectImg
                                            "
                                            ><img
                                                [src]="projectImg.projectImg"
                                                alt=""
                                                (click)="openModal(projectImg.projectImg)"
                                            />
                                        </ng-template>
                                    </owl-carousel-o>
                                    <div class="modal fade" id="imageModal" tabindex="-1" aria-labelledby="imageModalLabel" aria-hidden="true">
                                        <div class="modal-dialog  p-0 modal-dialog-centered">
                                          <div class="modal-content p-0">
                                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i class='bx bx-x'></i></button>
                                            <div class="modal-body">
                                              <img [src]="selectedImage" class="img-fluid" alt="Image Preview" />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                </div>

                                <div class="row">
                                    <div
                                        class="col-2"
                                        *ngFor="let clients of clients"
                                    >
                                        <div class="image">
                                            <img
                                                [src]="clients.clientTmg"
                                                alt="image"
                                            />
                                        </div>
                                    </div>
                                    <!-- <div class="col-2">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/logos/2.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                    </div>
                                    <div class="col-2">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/logos/3.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                    </div>
                                    <div class="col-2">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/logos/4.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                    </div>
                                    <div class="col-2">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/logos/5.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                    </div>
                                    <div class="col-2">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/logos/6.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                    </div> -->
                                </div>
                            </div>
                            <div class="tabs_item">
                                <div class="row">
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img1.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Creative Web Develop</h3>
                                                <span>Web Design</span>
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img2.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Digital Services</h3>
                                                <span>App Development</span>
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img3.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Complex Design</h3>
                                                <span
                                                    >Software Development</span
                                                >
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img4.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Creative Web Develop</h3>
                                                <span>React Development</span>
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img5.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Digital Services</h3>
                                                <span
                                                    >E-commerce
                                                    Development</span
                                                >
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img6.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Complex Design</h3>
                                                <span
                                                    >Software Engineering</span
                                                >
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tabs_item">
                                <div class="row">
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img1.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Creative Web Develop</h3>
                                                <span>Web Design</span>
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img2.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Digital Services</h3>
                                                <span>App Development</span>
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img3.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Complex Design</h3>
                                                <span
                                                    >Software Development</span
                                                >
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img4.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Creative Web Develop</h3>
                                                <span>React Development</span>
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img5.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Digital Services</h3>
                                                <span
                                                    >E-commerce
                                                    Development</span
                                                >
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img6.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Complex Design</h3>
                                                <span
                                                    >Software Engineering</span
                                                >
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tabs_item">
                                <div class="row">
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img1.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img2.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Digital Services</h3>
                                                <span>App Development</span>
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img3.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Complex Design</h3>
                                                <span
                                                    >Software Development</span
                                                >
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img4.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Creative Web Develop</h3>
                                                <span>React Development</span>
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img5.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Digital Services</h3>
                                                <span
                                                    >E-commerce
                                                    Development</span
                                                >
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img6.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Complex Design</h3>
                                                <span
                                                    >Software Engineering</span
                                                >
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="clients-section pt-100 pb-70">
    <div class="container-clint">
        <div class="section-title">
            <h2>
                {{ "our_clints.title_1" | translate }}
                <span>{{ "our_clints.title_2" | translate }}</span>
                {{ "our_clints.title_3" | translate }}
            </h2>
            <p>{{ "our_clints.desc" | translate }}</p>
            <div class="bar"></div>
        </div>
        <div class="clients-slider owl-carousel owl-theme">
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>{{ "our_clints.client_word_1" | translate }}</p>
                <div class="clients-content">
                    <h3>{{ "our_clints.client_name_1" | translate }}</h3>
                    <span>{{ "our_clints.client_jop_1" | translate }}</span>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>{{ "our_clints.client_word_2" | translate }}</p>
                <div class="clients-content">
                    <h3>{{ "our_clints.client_name_2" | translate }}</h3>
                    <span>{{ "our_clints.client_jop_2" | translate }}</span>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon bg-fce8c9">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>{{ "our_clints.client_word_3" | translate }}</p>
                <div class="clients-content">
                    <h3>{{ "our_clints.client_name_3" | translate }}</h3>
                    <span>{{ "our_clints.client_jop_3" | translate }}</span>
                </div>
            </div>
        </div>
    </div>
</section>

