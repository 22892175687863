<app-navbar-one></app-navbar-one>
<section class="contact-box pt-100 pb-70">
    <div class="container">
        <div class="row g-3 align-content-lg-stretch">
            <div class="col-lg-4 col-md-6">
                <div class="single-contact-box h-100">
                    <i class="flaticon-pin"></i>
                    <div class="content-title">
                        <h3>{{'contact.Address' | translate}}</h3>
                        <p>{{'contact.Address_1' | translate}}</p>
                        <p>{{'contact.Address_2' | translate}}</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-contact-box h-100">
                    <i class="flaticon-envelope"></i>
                    <div class="content-title">
                        <h3>{{'contact.Email' | translate}}</h3>
                        <a href="">{{'contact.Email_2' | translate}}</a>
                        <a href="">{{'contact.Email_1' | translate}}</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0 ">
                <div class="single-contact-box h-100">
                    <i class="flaticon-phone-call"></i>
                    <div class="content-title">
                        <h3>{{'contact.Phone' | translate}}</h3>
                        <a href="tel:123456123">{{'contact.Phone_1' | translate}}</a>
                        <a href="tel:126446129">{{'contact.Phone_2' | translate}}</a>
                        <a href="tel:126446129">{{'contact.Phone_3' | translate}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="contact-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="contact-text">
                    <h3>{{'contact.contact_form_title' | translate}}</h3>
                    <p>{{'contact.contact_form_Desc' | translate}}</p>
                </div>
                <div class="contact-form">
                    <form id="contactForm" [formGroup]="form" (submit)="send()">
                        <div class="form-group">
                            <input formControlName="from_name" type="text" name="name" id="name" class="form-control"  [placeholder]="'contact.placeholder_name' | translate">
                        </div>
                        <div class="form-group">
                            <input formControlName="from_email" type="email" name="email" id="email" class="form-control" [placeholder]="'contact.placeholder_email' | translate">
                        </div>
                        <div class="form-group">
                            <input formControlName="subject" type="text" name="msg_subject" id="msg_subject" class="form-control" [placeholder]="'contact.placeholder_subject' | translate">
                        </div>
                        <div class="form-group">
                            <textarea formControlName="message" name="message" class="form-control" id="message" cols="30" rows="6" [placeholder]="'contact.placeholder_message' | translate"></textarea>
                        </div>
                        <div class="send-btn">
                            <button type="submit" class="default-btn">{{'contact.Submit' | translate}}</button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 ">
                <div class="contact-image">
                    <img src="assets/img/contacat-logo.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
