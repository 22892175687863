<app-navbar-one></app-navbar-one>


<section class="protfolio-section">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="about-tab">
                    <div class="section-title">
                        <h2>
                            {{ "OUR_PORTFOLIO.TITLE_1_P1" | translate }}
                            <span>{{
                                "OUR_PORTFOLIO.TITLE_1_P2" | translate
                            }}</span>
                            {{ "OUR_PORTFOLIO.TITLE_1_P3" | translate }}
                        </h2>
                        <p>
                            {{ "OUR_PORTFOLIO.TITLE_2" | translate }}
                        </p>
                        <div class="bar"></div>
                    </div>
                    <div class="tab schedule-list-tab">
                        <ul class="tabs">
                            <li>
                                <a href="#"
                                    ><span
                                        ><i class="flaticon-internet"></i>
                                        {{
                                            "OUR_PORTFOLIO.head_line_title_1"
                                                | translate
                                        }}</span
                                    ></a
                                >
                            </li>
                            <li>
                                <a href="#"
                                    ><span
                                        ><i class="flaticon-optimize"></i>
                                        {{
                                            "OUR_PORTFOLIO.head_line_title_2"
                                                | translate
                                        }}</span
                                    ></a
                                >
                            </li>
                            <li>
                                <a href="#"
                                    ><span
                                        ><i class="flaticon-data"></i>
                                        {{
                                            "OUR_PORTFOLIO.head_line_title_3"
                                                | translate
                                        }}</span
                                    ></a
                                >
                            </li>
                            <li>
                                <a href="#"
                                    ><span
                                        ><i class="flaticon-software"></i>
                                        {{
                                            "OUR_PORTFOLIO.head_line_title_4"
                                                | translate
                                        }}</span
                                    ></a
                                >
                            </li>
                        </ul>
                        <div class="tab_content">
                            <div class="tabs_item">
                                <div class="mb-5 text-center">
                                    <h4 class="mb-5">
                                        {{
                                            "OUR_PORTFOLIO.LAST_PROJECT"
                                                | translate
                                        }}
                                    </h4>
                                    <owl-carousel-o [options]="customOptions" >
                                        <ng-template
                                            carouselSlide
                                            *ngFor="
                                                let projectImg of projectImg
                                            "
                                            ><img
                                                [src]="projectImg.projectImg"
                                                alt=""
                                                (click)="openModal(projectImg.projectImg)"
                                            />
                                        </ng-template>
                                    </owl-carousel-o>
                                    <div class="modal fade" id="imageModal" tabindex="-1" aria-labelledby="imageModalLabel" aria-hidden="true">
                                        <div class="modal-dialog  p-0 modal-dialog-centered">
                                          <div class="modal-content p-0">
                                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i class='bx bx-x'></i></button>
                                            <div class="modal-body">
                                              <img [src]="selectedImage" class="img-fluid" alt="Image Preview" />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                </div>

                                <div class="row">
                                    <div
                                        class="col-2"
                                        *ngFor="let clients of clients"
                                    >
                                        <div class="image">
                                            <img
                                                [src]="clients.clientTmg"
                                                alt="image"
                                            />
                                        </div>
                                    </div>
                                    <!-- <div class="col-2">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/logos/2.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                    </div>
                                    <div class="col-2">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/logos/3.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                    </div>
                                    <div class="col-2">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/logos/4.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                    </div>
                                    <div class="col-2">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/logos/5.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                    </div>
                                    <div class="col-2">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/logos/6.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                    </div> -->
                                </div>
                            </div>
                            <div class="tabs_item">
                                <div class="row">
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img1.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Creative Web Develop</h3>
                                                <span>Web Design</span>
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img2.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Digital Services</h3>
                                                <span>App Development</span>
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img3.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Complex Design</h3>
                                                <span
                                                    >Software Development</span
                                                >
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img4.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Creative Web Develop</h3>
                                                <span>React Development</span>
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img5.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Digital Services</h3>
                                                <span
                                                    >E-commerce
                                                    Development</span
                                                >
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img6.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Complex Design</h3>
                                                <span
                                                    >Software Engineering</span
                                                >
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tabs_item">
                                <div class="row">
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img1.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Creative Web Develop</h3>
                                                <span>Web Design</span>
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img2.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Digital Services</h3>
                                                <span>App Development</span>
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img3.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Complex Design</h3>
                                                <span
                                                    >Software Development</span
                                                >
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img4.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Creative Web Develop</h3>
                                                <span>React Development</span>
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img5.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Digital Services</h3>
                                                <span
                                                    >E-commerce
                                                    Development</span
                                                >
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img6.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Complex Design</h3>
                                                <span
                                                    >Software Engineering</span
                                                >
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tabs_item">
                                <div class="row">
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img1.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img2.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Digital Services</h3>
                                                <span>App Development</span>
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img3.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Complex Design</h3>
                                                <span
                                                    >Software Development</span
                                                >
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img4.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Creative Web Develop</h3>
                                                <span>React Development</span>
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img5.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Digital Services</h3>
                                                <span
                                                    >E-commerce
                                                    Development</span
                                                >
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="single-protfolio-box">
                                            <div class="image">
                                                <a
                                                    routerLink="/projects-details"
                                                    ><img
                                                        src="assets/img/protfolio/img6.png"
                                                        alt="image"
                                                /></a>
                                            </div>
                                            <div class="content">
                                                <h3>Complex Design</h3>
                                                <span
                                                    >Software Engineering</span
                                                >
                                                <a
                                                    routerLink="/projects-details"
                                                    class="link-btn"
                                                ></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>